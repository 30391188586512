export const environment = {
  API_URL: 'https://southamerica-east1-b4aprodution.cloudfunctions.net',
  production: true,
  firebase: {
    apiKey: "AIzaSyB8xKRMSWgSfutOTKcO4faJlVZj7ACC_Bo",
    authDomain: "b4aprodution.firebaseapp.com",
    projectId: "b4aprodution",
    storageBucket: "b4aprodution.appspot.com",
    messagingSenderId: "458755317337",
    appId: "1:458755317337:web:b6d4973ef80da370021b48"
},
  firebaseRegion: 'southamerica-east1',
  gaTackingId: 'G-DRQND1G9EJ',

  instaToken:
    'IGQVJWeHpWZA2szNlF2dkozS1plWE5VcjV4LWpCN2g3ZAEYxSzZAFQUQ2NFgtZAE5SQUVWaWdXdDdjM2puMFktVVlfQnpFOEMtWVhIWGc2MDh2ZAW5ZAbjgtQW1KZAkozTHFHTG9qVU5NYm1HZA2NqMjdLRnhuTQZDZD',
  instaApi: 'https://graph.instagram.com/me/media?access_token=',
  elasticSearch: {
    url: 'https://e4a30e39227142719915dfb4bd5240b5.us-central1.gcp.cloud.es.io:443',
    credential: 'MnBJVHY0Y0JtbEZ2dFBZRE82NGQ6WHdXQ1ZwTkhRQUNvS2s5WTNGSUNCUQ=='
  },
  hasura: {
    endpoint: 'https://hasura-products-36m4jjtkna-rj.a.run.app/v1/graphql',
    adminSecret: ''
  },
  apiUrl: 'https://subscription-api-prod.glambox.com.br/'
};
